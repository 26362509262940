var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-table',{ref:"table",attrs:{"columns":_vm.columns,"remote":_vm.remote,"query":_vm.query,"pagination":_vm.pagination,"row-key":"openId"},scopedSlots:_vm._u([{key:"code",fn:function(ref){
var row = ref.row;
return [_c('author-card',{attrs:{"value":row.author,"dataType":_vm.$root.dataType}})]}},{key:"oauthStatus",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"color":"red","font-size":"14px"}},[_vm._v(_vm._s(_vm.$dict.store.AUTHORIZATION_STATUS[row.oauthStatus][1]))])]}},{key:"refreshExpireTime",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.transform(row.refreshExpireTime)))])]}},{key:"operation",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":{ name: 'authorAuth', params: { authorId: row.authorId } },"target":"_blank"}},[_vm._v("编辑授权")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }