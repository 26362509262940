<template>
  <a-select show-search v-model="currentValue" :placeholder="placeholder" show-arrow :filter-option="false"
            :loading="loading" :allow-clear="allowClear" :disabled="disabled"
            @search="search" @change="handleChange" :default-active-first-option="false">
    <a-select-option v-for="item in options" :key="item.authorId" :value="item.authorId"
                     :title="`${item.nickname}(${item.code || '-'})`" :disabled="item.disabled">
      {{`${item.nickname}(${item.code || '-'})`}}
    </a-select-option>
  </a-select>
</template>

<script>

  export default {
    name: "author-select",
    props: {
      value: {
        required: true
      },
      allowClear: {
        type: Boolean
      },
      placeholder: {
        type: String,
        default: '请选择'
      },
      disabled: {
        type: Boolean
      }
    },
    watch: {
      '$root.dataType'() {
        this.$emit('input')
        this.$emit('change')
        this.loadData()
      }
    },
    computed: {
      currentValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val)
        }
      }
    },
    data: () => ({
      loading: false,
      options: []
    }),
    methods: {
      handleChange(val) {
        val || this.search()
        this.$emit('change', val);
      },
      search(keywords) {
        // keywords = (keywords || '').replace(/\[.*\]/g, '')
        // keywords = keywords.replace(/^\s+|\s+$/gm, '')
        keywords = keywords && keywords.trim()
        this.$nextTick(() => {
          (keywords || !this.currentValue) && this.loadData(keywords)
        })
      },
      loadData(keywords) {
        this.options = []
        this.loading = true
        this.$api.core.AuthorService.query({ keywords }).then(res => {
          this.options = res.data
        }).finally(() => {
          this.loading = false
        })
      }
    },
    created() {
      this.search();
    }
  }
</script>
