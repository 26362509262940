<template>
  <div>
    <a-form-model layout="inline" class="panel panel-default panel-search">
      <a-col flex="auto">
        <a-form-model-item :label="`${$root.dataTypeLabel}播主`">
          <author-select v-model="query.param.authorId" allow-clear :placeholder="`输入昵称/${$root.dataTypeLabel}号`"
                         style="width:205px" ref="authorSelect"></author-select>
        </a-form-model-item>
        <a-form-model-item label="主机厂" >
          <manufactor-select v-model="query.manufactorId" allow-clear style="width:205px" ref="manufactorSelect"></manufactor-select>
        </a-form-model-item>
        <a-form-model-item label="经销商">
          <dealer-select v-model="query.dealerId" allow-clear style="width:205px" ref="dealerSelect"></dealer-select>
        </a-form-model-item>
        <a-form-model-item label="授权状态">
          <template v-if="activeTab == 'OpenPlatform'">
            <a-select v-model="query.param.oauthStatus" :allow-clear="true" placeholder="请选择"  style="width:120px">
              <a-select-option v-for="item in $dict.store.AUTHORIZATION_STATUS" :key="item[0]" :value="item[0]" :title="item[1]">
                {{item[1]}}
              </a-select-option>
            </a-select>
          </template>
          <template v-else>
            <a-select v-model="query.param.status" :allow-clear="true" placeholder="请选择"  style="width:120px">
              <a-select-option v-for="item in $dict.store.SERVICEPLATFORM_STATUS" :key="item[0]" :value="item[0]" :title="item[1]">
                {{item[1]}}
              </a-select-option>
            </a-select>
          </template>
        </a-form-model-item>
      </a-col>
      <a-col class="tools">
        <a-button type="primary" html-type="submit" @click="search">查询</a-button>
        <a-button type="link" @click="clear" style="margin-left:8px">重置</a-button>
      </a-col>
    </a-form-model>
    <a-tabs :active-key="activeTab" @change="onTabChange">
      <a-tab-pane key="OpenPlatform" tab="互动数据授权">
        <open-platform-tab ref="OpenPlatform" :query="query"></open-platform-tab>
      </a-tab-pane>
      <a-tab-pane key="ServicePlatform" tab="直播数据授权">
        <service-platform-tab ref="ServicePlatform" :query="query"></service-platform-tab>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<style scoped>
.ant-table .ant-list-item {
  padding: 0;
}
</style>
<script>

import AuthorSelect from "@/components/widgets/author-select"
import DealerSelect from "@/components/widgets/dealer-select"
import ManufactorSelect from "@/components/widgets/manufactor-select";
import OpenPlatformTab from "./OpenPlatformTab";
import ServicePlatformTab from "./ServicePlatformTab";


let defaultQuery = {
  // orderBys: "authorId|desc",
  param: {
    authorId: undefined,
    oauthStatus: undefined,
    isDeleted: 0
  },
  manufactorId: undefined,
  dealerId: undefined,
};

export default {
  components: {DealerSelect, ManufactorSelect ,AuthorSelect,OpenPlatformTab,ServicePlatformTab},
  data() {
    return {
      orderDateRange: [],
      query: _.cloneDeep(defaultQuery),
      loading: false,
      loadingSummary:false,
      pagination: { pageSize: 10 },
      visible: false,
      validDate:'',
      activeTab: 'OpenPlatform'
    }
  },
  computed: {
  },
  methods: {
    clear() {
      this.query = _.cloneDeep(defaultQuery);
      this.$nextTick(() => {
        this.search(true);
      });
    },
    init(options = {}) {
      this.onTabChange(this.activeTab)
    },
    onTabChange(activeTab) {
      this.activeTab = activeTab
      this.$nextTick(() => this.search())
    },
    search() {
      this.$refs[this.activeTab].$emit('init')
      this.$refs.authorSelect.loadData('')
      this.$refs.manufactorSelect.loadData('')
      this.$refs.dealerSelect.loadData('')
    }
  },
  watch:{

  },
  created() {},
  mounted() {
    this.$on("init", this.init);
  }
};
</script>
